import { h } from "preact";
import corbin from "../assets/images/corbin.svg";
import geoclimatica from "../assets/images/geoclimatica-inline.svg";
import styles from "./credits.css";

export const Credits = () => {
  return (
    <div class={styles.container}>
      <div class={styles.credits}>
        <span class={styles.credit}>
          Brought to you by{" "}
          <a href="http://geoclimatica.com">
            <img class={styles.logo} src={geoclimatica} alt="Geoclimatica" />
          </a>
        </span>
        <span class={`${styles.credit} ${styles.creditRight}`}>
          <a href="http://corbin.uk">
            <img
              class={`${styles.logo} ${styles.logoLeecorbin}`}
              src={corbin}
              alt="Corbin"
            />
          </a>
        </span>
      </div>
      <div class={styles.copyright}>
        Copyright &copy; Blaenavon Weather, 2025
      </div>
    </div>
  );
};
