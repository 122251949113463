import { h } from "preact";
import styles from "./Flash.css";

const Flash = () => {
  return (
    <div class={styles.flash}>
      The highest live
      <br />
      weather station cam
      <br />
      in Wales
    </div>
  );
};

export default Flash;
